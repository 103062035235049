<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ title }} - {{ form.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>

      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!loading" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="8">
              <v-text-field
                name="name"
                label="Name"
                id="name"
                v-model="form.name"
                :rules="rules.name"
                :disabled="!isEditing"
                class="required"
                :error-messages="$error.getValidationError(errors, 'name')"
                @input="$error.clearValidationError(errors, 'name')"
              ></v-text-field>

              <RuleConditionField
                label="Expression"
                rows="4"
                :disabled="!isEditing"
                v-if="form.ruleCondition"
                v-model="form.ruleCondition"
              />
            </v-col>
            <v-col cols="4">
              <h2>Information</h2>
              <ul>
                <li>
                  A <b>{{ title }}</b> is a user-defined expression that can be used to calculate rules engine logic.
                </li>
                <li>A rule formula's <b>Name</b> is unique and can be used in other rule-based expressions.</li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import ApiError from "../../display/ApiError.vue";
import RuleConditionField from "../../fields/RuleConditionField.vue";

export default {
  name: "RuleFormula",
  props: {
    title: {
      type: String,
      default: "Rule Formula"
    }
  },
  metaInfo() {
    return {
      title: this.title
    };
  },
  components: {
    ApiError,
    RuleConditionField
  },
  data: function() {
    return {
      valid: false,
      isNew: false,
      isEditing: false,
      loading: false,
      form: {
        ruleCondition: {
          spelExpression: ""
        }
      },
      errors: {},
      rules: {
        name: [v => (!!v && v.indexOf(" ") == -1) || "Name is required and cannot contain spaces"]
      }
    };
  },
  methods: {
    onValidate(form) {
      return this.$api.post("/api/ruleFormulas/validate", form);
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let postForm = Object.assign({}, this.form);

      return this.onValidate(postForm)
        .then(() => {
          console.log("did not go to catch");
          if (this.isNew) {
            return this.$api.post("/api/ruleFormulas/create", postForm);
          } else {
            return this.$api.post("/api/ruleFormulas/" + this.$route.params.id + "/update", postForm);
          }
        })
        .then(({ data }) => {
          this.form = data;
          this.isEditing = false;
          if (this.isNew) {
            this.isNew = false;
            this.$route.params.id = data.id;
          }
          return this.fetchData();
        })
        .catch(error => {
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    fetchData() {
      if (!this.isNew) {
        this.loading = true;
        return this.$api.get("/api/ruleFormulas/" + this.$route.params.id).then(({ data }) => {
          this.loading = false;
          this.form = data;
          this.breadcrumb();
          return Promise.all([
            this.$api.getRelatedObject("ruleCondition", data).then(({ data }) => {
              Vue.set(this.form, "ruleCondition", data);
            })
          ]);
        });
      } else {
        return Promise.resolve();
      }
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: this.title,
            to: { name: "ruleFormulas" },
            exact: true
          },
          { text: "New " + this.title }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: this.title,
            to: { name: "ruleFormulas" },
            exact: true
          },
          { text: this.form.name }
        ]);
      }
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"])
  },

  mounted() {
    this.ruleFormulaId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.form.program = this.$api.getSelfUrl("programs", this.selectedProgram);
      this.breadcrumb();
    }

    this.fetchData();
  }
};
</script>
